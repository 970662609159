<template>
  <div id="timeRule">
    <el-form
      :model="timeRuleForm"
      :rules="detailRules"
      ref="timeRuleForm"
      label-width="70px"
    >
      <div class="timeQuantum">
        <div class="longCharge">
          <el-form-item
            v-for="(item, index) of timeRuleForm.priceUnitList"
            :key="item.id"
            :label="'时长' + (index + 1) + ':'"
            :prop="`priceUnitList_${index}`"
          >
            <div class="priceUnit">
              <div>
                <el-input
                  v-model="item.fromTime"
                  :disabled="index === 0"
                ></el-input>

                <el-select
                  v-model="item.countUnit"
                  @change="countUnitChange($event)"
                >
                  <el-option
                    v-for="unit of unitList"
                    :key="unit.value"
                    :label="unit.label"
                    :value="unit.value"
                  ></el-option>
                </el-select>
              </div>
              <span>-</span>
              <div>
                <el-input v-model="item.toTime"></el-input>

                <el-select
                  v-model="item.countUnit"
                  @change="countUnitChange($event)"
                >
                  <el-option
                    v-for="unit of unitList"
                    :key="unit.value"
                    :label="unit.label"
                    :value="unit.value"
                  ></el-option>
                </el-select>
              </div>
            </div>
            <div class="priceUnitList_charge">
              <span>收费</span>
              <div>
                <div class="chargeAmount">
                  <el-input v-model="item.chargeAmount">
                    <span slot="suffix">元</span></el-input
                  >
                </div>
              </div>

              <span class="obliqueLine">/</span>
              <div>
                <el-input v-model="item.chargeTime"></el-input>
                <el-select v-model="item.chargeUnit" placeholder="">
                  <el-option
                    v-for="unit of unitList"
                    :key="unit.value"
                    :label="unit.label"
                    :value="unit.value"
                  ></el-option>
                </el-select>
              </div>
            </div>
            <el-button
              type="text"
              @click="delPriceUnit(index)"
              v-if="
                index !== 0 && index === timeRuleForm.priceUnitList.length - 1
              "
              >删除</el-button
            >
          </el-form-item>
          <el-button size="medium" class="addPriceUnit" @click="addPriceUnit"
            ><i class="iconfont icon-jiahao"></i> 添加时长</el-button
          >
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
export default {
  props: ["periodHours"],
  data() {
    return {
      //单位列表
      unitList: [
        {
          value: "分钟",
          babel: "分钟",
        },
        {
          value: "小时",
          babel: "小时",
        },
      ],
      timeRuleForm: {
        priceUnitList: [
          {
            //生成随机数做key值
            id: Math.random(),
            //时长的开始时长
            fromTime: "0",
            //时长的结束时长
            toTime: "",
            //时长的时长单位
            countUnit: "小时",
            //收费的金额
            chargeAmount: "",
            //收费的时长
            chargeTime: "",
            //收费的时长单位
            chargeUnit: "小时",
          },
        ],
      },
      detailRules: {
        //0表示第几个时长
        priceUnitList_0: [{ validator: this.priceUnit_relus, trigger: "blur" }],
      },
    };
  },
  methods: {
    //修改时长的单位
    countUnitChange(event) {
      let priceUnitList = this.timeRuleForm.priceUnitList;
      for (let item of priceUnitList) {
        item.countUnit = event;
      }
    },
    reset() {
      this.timeRuleForm = {
        priceUnitList: [
          {
            //生成随机数做key值
            id: Math.random(),
            //时长的开始时长
            fromTime: "0",
            //时长的结束时长
            toTime: "",
            //时长的时长单位
            countUnit: "小时",
            //收费的金额
            chargeAmount: "",
            //收费的时长
            chargeTime: "",
            //收费的时长单位
            chargeUnit: "小时",
          },
        ],
      };
    },
    //删除时长
    delPriceUnit(index) {
      this.timeRuleForm.priceUnitList.splice(index, 1);
    },
    //校验添加时长的逻辑
    priceUnit_relus(rule, value, callback) {
      let index = parseInt(rule.field.split("_")[1]);
      let priceUnitList = this.timeRuleForm.priceUnitList;

      if (!priceUnitList[index].toTime) {
        return callback(new Error("请输入结束时长"));
      }
      if (!(priceUnitList[index].chargeAmount >= 0)) {
        return callback(new Error("请输入收费金额"));
      }
      if (!priceUnitList[index].chargeTime) {
        return callback(new Error("请输入收费的时长"));
      }

      let countUnit = priceUnitList[0].countUnit;
      let diff =
        countUnit === "小时" ? this.periodHours : this.periodHours * 60;

      if (index === 0) {
        if (priceUnitList[index].toTime > diff) {
          return callback(new Error(`结束时长不能超过${diff}${countUnit}`));
        } else {
          return callback();
        }
      } else {
        if (
          Number(priceUnitList[index].fromTime) <
          priceUnitList[index - 1].toTime
        ) {
          return callback(
            new Error(
              `该段开始时长需大于等于${priceUnitList[index - 1].toTime}`
            )
          );
        } else if (
          priceUnitList[index].toTime > diff ||
          Number(priceUnitList[index].toTime) <=
            Number(priceUnitList[index].fromTime)
        ) {
          return callback(
            new Error(
              `该段结束时长范围需在${parseInt(
                priceUnitList[index].fromTime
              )}~${diff}${countUnit}之间`
            )
          );
        } else {
          return callback();
        }
      }
    },
    //添加时长
    addPriceUnit() {
      this.$refs["timeRuleForm"].validate((valid) => {
        if (valid) {
          let priceUnitList = this.timeRuleForm.priceUnitList;
          let last_priceUnit = priceUnitList[priceUnitList.length - 1];

          let countUnit = priceUnitList[0].countUnit;
          let diff =
            countUnit === "小时" ? this.periodHours : this.periodHours * 60;

          if (last_priceUnit.toTime >= diff) {
            this.$message.error(
              `时长${priceUnitList.length}的结束时间已经为最大值`
            );
          } else {
            Object.assign(this.detailRules, {
              [`priceUnitList_${priceUnitList.length}`]: [
                { validator: this.priceUnit_relus, trigger: "blur" },
              ],
            });
            this.timeRuleForm.priceUnitList.push({
              id: Math.random(),
              fromTime: last_priceUnit.toTime,
              toTime: "",
              countUnit: last_priceUnit.countUnit,
              chargeAmount: "",
              chargeTime: "",
              chargeUnit: "小时",
            });
          }
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss">
#timeRule {
}
</style>
